import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { RoomMoreMenu } from '../components/_dashboard/user';

import api from '../services/api';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// ----------------------------------------------------------------------

export default function Room() {
  const [rooms, setRooms] = useState([]);
  const [update, setUpdate] = useState(0);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };

  const apiGetRooms = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/rooms`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setRooms(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (update !== 0) {
      setSnack(true);
    }
    apiGetRooms();
  }, [update]);

  return (
    <Page title="Salas | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todas Salas
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/salas/criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar Novo
          </Button>
        </Stack>

        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome da Sala</TableCell>
                    <TableCell align="right">Descrição</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rooms.map((room) => (
                    <TableRow key={room?.id} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row">
                        {room?.title}
                      </TableCell>
                      <TableCell align="right">{room?.description}</TableCell>
                      <TableCell align="right">
                        <RoomMoreMenu id={room?.id} setUpdate={setUpdate} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Sala Deletada com Sucesso
        </Alert>
      </Snackbar>
    </Page>
  );
}
