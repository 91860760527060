import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import bookFill from '@iconify/icons-eva/book-fill';
import cubeFill from '@iconify/icons-eva/cube-fill';
import fileTextOutline from '@iconify/icons-eva/file-text-outline';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import percentOutline from '@iconify/icons-eva/percent-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Funcionários',
    path: '/dashboard/funcionarios',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Pacientes',
    path: '/dashboard/pacientes',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Agendamentos',
    path: '/dashboard/agendamentos/filtro',
    icon: getIcon(percentOutline)
  },
  {
    title: 'Agenda',
    path: '/dashboard/agendamento',
    icon: getIcon(bookFill)
  },
  {
    title: 'Salas',
    path: '/dashboard/salas',
    icon: getIcon(cubeFill)
  },
  {
    title: 'Notas Fiscais',
    path: '/dashboard/notas-fiscais',
    icon: getIcon(fileTextOutline)
  }
];

export const sidebarProfessionalConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Pacientes',
    path: '/dashboard/pacientes/profissional',
    icon: getIcon(personAddFill)
  }
];
