// material
import { useState } from 'react';
import { styled } from '@mui/material/styles';
// layouts
import { Container, Typography, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import AuthLayout from '../layouts/AuthLayout';
import api from '../services/api';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sucess, setSucess] = useState(false);

  const recoveryPasswordAPI = async () => {
    setIsSubmitting(true);
    const payload = { email };
    try {
      await api.post('/password/forgot', payload, {
        headers: {
          Accept: 'application/json'
        }
      });
      setSucess(true);
    } catch (e) {
      setIsSubmitting(false);
      setSucess(false);
      alert('Vejo que adicionou algum e-mail que não existe');
    }
  };

  const handleSubmit = () => recoveryPasswordAPI();

  return (
    <RootStyle title="Login">
      <AuthLayout />
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Recupere sua senha para o Sistema da Pscicologia Médica
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        {sucess ? (
          <ContentStyle>
            <Typography variant="h3" align="center" sx={{ color: 'text.secondary' }}>
              Foi enviado em seu e-mail para recuperar sua senha!
            </Typography>
          </ContentStyle>
        ) : (
          <ContentStyle>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              InputLabelProps={{ shrink: true }}
              type="email"
              label="Insira o E-mail do seu usuário"
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              loading={isSubmitting}
              sx={{ my: 2 }}
            >
              Recuperar Senha
            </LoadingButton>
          </ContentStyle>
        )}
      </Container>
    </RootStyle>
  );
}
