import {
  Card,
  CardHeader,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer
} from '@mui/material';

const rows = [
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  },
  {
    name: 'Marcos Alvim Faria',
    birthdate: '28/02'
  }
];

export default function AppBirthDate() {
  return (
    <Card>
      <CardHeader title="Aniversariantes do Mês" />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome completo</TableCell>
              <TableCell align="right">Data de Nascimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ py: 3 }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.birthdate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
