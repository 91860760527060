import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  Input,
  InputLabel,
  Grid
} from '@mui/material';
import { IMaskInput } from 'react-imask';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { formattedBirthdate } from '../_user/utils';

import api from '../../services/api';

const BirthdateMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

export default function CreateMedicalReport() {
  const { clientId } = useParams();
  const [client, setClient] = React.useState();
  const [user, setUser] = React.useState();
  const navigate = useNavigate();
  const createAPI = async (values) => {
    try {
      await api.post(`/medical-records/${clientId}`, values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate(`/dashboard/prontuario/${clientId}`, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const getClient = async () => {
    try {
      const clientData = await api.get(`/clients/${clientId}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setClient(clientData.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getUser = async () => {
    try {
      const userData = await api.get(`/self`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUser(userData.data);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    getClient();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreateSchema = Yup.object().shape({
    description: Yup.string().nullable(),
    familiar_description: Yup.string().nullable(),
    profissional_description: Yup.string().nullable(),
    diagnostic_hypothesis: Yup.string().nullable(),
    therapeutic_conduct: Yup.string().nullable(),
    case_evolution: Yup.string().nullable(),
    forwarding_record: Yup.string().nullable(),
    closing: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      date: null,
      description: null,
      familiar_description: null,
      profissional_description: null,
      diagnostic_hypothesis: null,
      therapeutic_conduct: null,
      case_evolution: null,
      forwarding_record: null,
      closing: null,
      date_end: null
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      values.date = formattedBirthdate(values.date);
      values.date_end = formattedBirthdate(values.date_end);

      createAPI(values);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Cadastrar novo Prontuário Eletrônico">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Criar um novo Prontuário Eletrônico:
          </Typography>
        </Stack>

        <Card sx={{ p: 5, mb: 2 }}>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h4" gutterBottom>
                Identificação do Profissional:
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h6" gutterBottom>
                Nome: {user?.name}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                Formação: {user?.occupation}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                CRP: {user?.crp}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ p: 5, mb: 2 }}>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h4" gutterBottom>
                Identificação Paciente:
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                Nome: {client?.name}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                Data de Nascimento: {formattedBirthdate(client?.birthdate)}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações:
                </Typography>

                <div>
                  <InputLabel htmlFor="Data">Data de Início do Processo</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    label="Data"
                    {...getFieldProps('date')}
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    inputComponent={BirthdateMaskInput}
                  />
                </div>

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Descrição da Demanda"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Histório Familiar"
                  {...getFieldProps('familiar_description')}
                  error={Boolean(touched.familiar_description && errors.familiar_description)}
                  helperText={touched.familiar_description && errors.familiar_description}
                />

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Histório Profissional"
                  {...getFieldProps('profissional_description')}
                  error={Boolean(
                    touched.profissional_description && errors.profissional_description
                  )}
                  helperText={touched.profissional_description && errors.profissional_description}
                />

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Hipótese Diagnóstica"
                  {...getFieldProps('diagnostic_hypothesis')}
                  error={Boolean(touched.diagnostic_hypothesis && errors.diagnostic_hypothesis)}
                  helperText={touched.diagnostic_hypothesis && errors.diagnostic_hypothesis}
                />

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Conduta terapêutica"
                  {...getFieldProps('therapeutic_conduct')}
                  error={Boolean(touched.therapeutic_conduct && errors.therapeutic_conduct)}
                  helperText={touched.therapeutic_conduct && errors.therapeutic_conduct}
                />

                <TextField
                  multiline
                  minRows={12}
                  type="text"
                  label="Evolução de Caso"
                  {...getFieldProps('case_evolution')}
                  error={Boolean(touched.case_evolution && errors.case_evolution)}
                  helperText={touched.case_evolution && errors.case_evolution}
                />

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Registro de Encaminhamento"
                  {...getFieldProps('forwarding_record')}
                  error={Boolean(touched.forwarding_record && errors.forwarding_record)}
                  helperText={touched.forwarding_record && errors.forwarding_record}
                />

                <TextField
                  multiline
                  minRows={3}
                  type="text"
                  label="Encerramento"
                  {...getFieldProps('closing')}
                  error={Boolean(touched.closing && errors.closing)}
                  helperText={touched.closing && errors.closing}
                />
                <div>
                  <InputLabel htmlFor="Data">Data de Encerramento</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    label="Data"
                    {...getFieldProps('date_end')}
                    error={Boolean(touched.date_end && errors.date_end)}
                    helperText={touched.date_end && errors.date_end}
                    inputComponent={BirthdateMaskInput}
                  />
                </div>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Cadastrar Novo Prontuário
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
