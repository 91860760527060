/* eslint-disable react/prop-types */
import { useState } from 'react';

import { Button, Input, Select, MenuItem, Autocomplete, TextField } from '@mui/material';

import { formattedDate } from '../_user/utils';

export function FilterForm({ setFilter, filter, clients, users, isShedule = true }) {
  const [user, setUser] = useState(filter?.userId);
  const [client, setClient] = useState(filter?.clientId);
  const [dateStart, setDateStart] = useState(formattedDate(filter?.dateStart));
  const [dateEnd, setDateEnd] = useState(formattedDate(filter?.dateEnd));
  const [status, setStatus] = useState(filter?.status ?? ' ');

  const clientsSelect = [];

  clients.map((client) =>
    clientsSelect.push({
      id: client.id,
      label: client.name
    })
  );

  const usersSelect = [];

  users.map((user) =>
    usersSelect.push({
      id: user.id,
      label: user.name
    })
  );

  const handleFilter = () => {
    let statusFormat = status;
    if (status === ' ') {
      statusFormat = '';
    }
    setFilter({
      dateStart,
      dateEnd,
      userId: user,
      clientId: client,
      status: statusFormat
    });
  };

  return (
    <div style={{ padding: 10 }}>
      <div>
        <Input
          style={{ margin: 10, width: 300 }}
          type="date"
          label="Data Início"
          value={dateStart}
          onChange={(e) => setDateStart(e.target.value)}
          variant="filled"
        />
        <Input
          style={{ margin: 10, width: 300 }}
          type="date"
          label="Data Final"
          value={dateEnd}
          onChange={(e) => setDateEnd(e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', height: '150px', marginTop: '15px' }}>
        <Autocomplete
          disablePortal
          id="select-clients"
          sx={{ width: 300, marginRight: '10px' }}
          options={clientsSelect}
          onChange={(e, value) => setClient(value?.id)}
          renderInput={(params) => <TextField {...params} label="Paciente" />}
        />
        <Autocomplete
          disablePortal
          id="select-users"
          sx={{ width: 300, marginRight: '10px' }}
          options={usersSelect}
          onChange={(e, value) => setUser(value?.id)}
          renderInput={(params) => <TextField {...params} label="Profissional" />}
        />
        {isShedule && (
          <Select
            InputLabelProps={{ shrink: true }}
            sx={{ width: 300, height: '56px', marginRight: '10px' }}
            placeholder="Status do Agendamento"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value=" ">Todos Status</MenuItem>
            <MenuItem value="Não Pago">Não Pago</MenuItem>
            <MenuItem value="Pagamento Pendente">Pagamento Pendente</MenuItem>
            <MenuItem value="Pago">Pago</MenuItem>
            <MenuItem value="Não Compareceu">Não Compareceu</MenuItem>
            <MenuItem value="Isento">Isento</MenuItem>
          </Select>
        )}
      </div>

      <Button variant="outlined" onClick={handleFilter} sx={{ float: 'right' }}>
        Filtrar
      </Button>
    </div>
  );
}
