import { useState, useEffect } from 'react';

// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { formatDateYYYYMMDD } from '../utils/formatTime';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { FilterForm } from './_filterSchedule/FilterForm';

import api from '../services/api';
import { formattedBirthdate } from './_user/utils';

// ----------------------------------------------------------------------

export default function FilterSchedule() {
  const { dateStart, dateEnd } = formatDateYYYYMMDD();

  const [schedules, setSchedules] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ dateStart, dateEnd });

  const getClients = async () => {
    const request = await api.get(`/clients`, {
      headers: {
        Accept: 'application/json'
      }
    });

    setClients(request.data);
  };

  const getUsers = async () => {
    const request = await api.get(`/users`, {
      headers: {
        Accept: 'application/json'
      }
    });

    setUsers(request.data);
  };

  useEffect(() => {
    getClients();
    getUsers();
  }, []);

  const apiGetSchedules = async () => {
    try {
      setLoading(true);
      const request = await api.post(`/schedules/list`, filter, {
        headers: {
          Accept: 'application/json'
        }
      });

      setSchedules(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    apiGetSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Page title="Agendamentos | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todos os Agendamentos
          </Typography>
        </Stack>
        <Card sx={{ margin: '15px 0', padding: '10px' }}>
          <FilterForm setFilter={setFilter} filter={filter} clients={clients} users={users} />
        </Card>
        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Paciente</TableCell>
                    <TableCell>Profissional</TableCell>
                    <TableCell align="right">Valor Combinado</TableCell>
                    <TableCell align="right">Valor em Dinheiro</TableCell>
                    <TableCell align="right">Valor Transferido</TableCell>
                    <TableCell align="right">Data da Consulta</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedules.map((schedule) => (
                    <TableRow key={schedule?.id} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row">
                        {schedule?.client?.name}
                      </TableCell>
                      <TableCell align="right">{schedule?.user?.name}</TableCell>
                      <TableCell align="right">{schedule?.agreed_value}</TableCell>
                      <TableCell align="right">{schedule?.cash_value}</TableCell>
                      <TableCell align="right">{schedule?.transfer_value}</TableCell>
                      <TableCell align="right">
                        {formattedBirthdate(schedule?.start_datetime.substring(0, 10))}
                      </TableCell>
                      <TableCell align="right">{schedule?.status}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          component={RouterLink}
                          target="_blank"
                          to={`/dashboard/agendamento/${schedule?.user_id}/editar/${schedule?.id}`}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
    </Page>
  );
}
