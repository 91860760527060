import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button
} from '@mui/material';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

import api from '../services/api';

// ----------------------------------------------------------------------

export default function Schedule() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiGetUsers = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/users`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUsers(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    apiGetUsers();
  }, []);

  return (
    <Page title="Funcionários | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todos os Funcionários
          </Typography>
        </Stack>

        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome completo</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Ocupação</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.name} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row">
                        {user?.name}
                      </TableCell>
                      <TableCell align="right">{user?.email}</TableCell>
                      <TableCell align="right">{user?.occupation}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/agendamento/${user.id}`}
                        >
                          Ver Calendário
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
    </Page>
  );
}
