import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptBR from '@fullcalendar/core/locales/pt-br';

import { Stack, Container, Typography, Card } from '@mui/material';

import Page from '../../components/Page';
import api from '../../services/api';

export default function Calendar() {
  const { roomId } = useParams();

  const [room, setRoom] = useState({});
  const [schedules, setSchedules] = useState({});
  const [load, setLoad] = useState(false);

  const colorEvent = (status) => {
    switch (status) {
      case 'Não Pago':
        return 'gray';
      case 'Pagamento Pendente':
        return 'gray';
      case 'Pago':
        return 'blue';
      case 'Isento':
        return 'blue';
      case 'Não Compareceu':
        return 'red';
      default:
        return 'blue';
    }
  };

  const getClientByID = (id, clients) => {
    const getClient = clients.find((client) => client.id === id);
    return getClient.name;
  };

  const getAPI = async () => {
    try {
      const response = await api.get(`/rooms/${roomId}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      setRoom(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getCorrectDate = (date) => {
    const today = new Date(date);
    if (window.location.origin === 'https://admin.psicologiamedica.com.br') {
      today.setHours(today.getHours() + 3);
    } else {
      today.setHours(today.getHours());
    }
    return today;
  };

  const getSchedule = async () => {
    try {
      const response = await api.get(`/schedules/room/${roomId}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      const clients = await api.get('/clients', {
        headers: {
          Accept: 'application/json'
        }
      });

      let scheduleFormat;

      const allSchedules = response.data.map((data) => {
        scheduleFormat = {
          id: data.id,
          start: getCorrectDate(data.start_datetime),
          end: getCorrectDate(data.end_datetime),
          title: getClientByID(data.client_id, clients.data),
          backgroundColor: colorEvent(data.status)
        };
        return scheduleFormat;
      });

      setSchedules(allSchedules);
      setLoad(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAPI();
    getSchedule();
  }, []);

  return (
    <Page title="Ver Agendamentos das Salas">
      <Container>
        <Stack direction="column" mb={5}>
          <Typography variant="h3" display="block">
            Sala: {room?.title}
          </Typography>
        </Stack>
        <Card sx={{ p: 5 }}>
          {load && (
            <FullCalendar
              firstDay={1}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              locale={ptBR}
              initialEvents={schedules}
              selectable
              selectMirror
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
