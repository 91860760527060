import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  FormControlLabel
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { formattedBirthdate } from '../_user/utils';

import api from '../../services/api';

const getFormatDateTime = (datetime) => {
  const date = datetime.slice(0, 10);
  const time = datetime.slice(11, 16);
  return { date, time };
};

export default function CreateSchedule() {
  const navigate = useNavigate();
  const { user } = useParams();
  const [searchParams] = useSearchParams();
  const [clients, setClients] = React.useState([]);
  const [profile, setProfile] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const [isNewUser, setIsNewUser] = React.useState(false);
  const start = getFormatDateTime(searchParams.get('inicio'));
  const end = getFormatDateTime(searchParams.get('fim'));

  const apiGetClients = async () => {
    try {
      const request = await api.get(`/clients`, {
        headers: {
          Accept: 'application/json'
        }
      });
      const sortClients = request.data.sort((a, b) => a.name - b.name);

      setClients(sortClients);
    } catch (e) {
      console.error(e);
    }
  };

  const getAPI = async () => {
    try {
      const response = await api.get(`/users/${user}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      setProfile(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getRooms = async () => {
    try {
      const request = await api.get(`/rooms`, {
        headers: {
          Accept: 'application/json'
        }
      });
      const sortRooms = request.data.sort((a, b) => a.title - b.title);

      setRooms(sortRooms);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    apiGetClients();
    getAPI();
    getRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatredValue = (value) => {
    value = value.replace(',', '.');
    value = value.split('.');
    let returValue = value[0];
    if (value[1]) {
      returValue = `${value[0]}.${value[1].slice(0, 2)}`;
    }
    return returValue;
  };

  const createClient = async (name) => {
    const values = { name };

    try {
      const newUser = await api.post('/clients', values, {
        headers: {
          Accept: 'application/json'
        }
      });
      return newUser.data;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const createAPI = async (values) => {
    if (values.agreed_value) values.agreed_value = formatredValue(values.agreed_value);
    if (values.cash_value) values.cash_value = formatredValue(values.cash_value);
    if (values.transfer_value) values.transfer_value = formatredValue(values.transfer_value);
    values.start_datetime = `${formattedBirthdate(values.date)} ${values.timeStart}`;
    values.end_datetime = `${formattedBirthdate(values.date)} ${values.timeEnd}`;
    values.status = 'Não Pago';
    values.user_id = profile.id;
    try {
      await api.post('/schedules', values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate(`/dashboard/agendamento/${profile.id}`, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const createBulkAPI = async (values) => {
    if (values.agreed_value) values.agreed_value = formatredValue(values.agreed_value);
    if (values.cash_value) values.cash_value = formatredValue(values.cash_value);
    if (values.transfer_value) values.transfer_value = formatredValue(values.transfer_value);
    values.start_datetime = `${formattedBirthdate(values.date)} ${values.timeStart}`;
    values.end_datetime = `${formattedBirthdate(values.date)} ${values.timeEnd}`;
    values.is_bulk = true;
    values.status = 'Não Pago';
    values.user_id = profile.id;
    try {
      await api.post('/schedules/bulk', values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate(`/dashboard/agendamento/${profile.id}`, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const CreateSchema = Yup.object().shape({
    room_id: Yup.string().required('Sala é obrigatório'),
    timeStart: Yup.string().required('Horário é obrigatório'),
    timeEnd: Yup.string().required('Horário é obrigatório')
  });

  const formik = useFormik({
    initialValues: {
      date: formattedBirthdate(start.date),
      timeStart: start.time,
      timeEnd: end.time
    },
    validationSchema: CreateSchema,
    onSubmit: async (values) => {
      if (!values.client_id && values.clientName) {
        const newClient = await createClient(values.clientName);
        values.client_id = newClient.id;
      }
      if (values.frequencyBulk) {
        createBulkAPI(values);
      } else {
        createAPI(values);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const clientsSelect = [];

  clients.map((client) =>
    clientsSelect.push({
      id: client.id,
      label: client.name
    })
  );

  return (
    <Page title="Novo Agendamento">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Novo Agendamento para {profile?.name}
          </Typography>
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações do Agendamento
                </Typography>

                <InputLabel id="simple-select-label">Agendamento para o(a) paciente:</InputLabel>

                {!isNewUser && (
                  <Autocomplete
                    disablePortal
                    id="select-clients"
                    options={clientsSelect}
                    onChange={(e, value) => setFieldValue('client_id', value?.id || '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.client_id && errors.client_id)}
                        helperText={touched.client_id && errors.client_id}
                        label="Paciente"
                      />
                    )}
                  />
                )}

                <FormControlLabel
                  control={<Checkbox onChange={(e) => setIsNewUser(e.target.checked)} />}
                  label="Nova(o) Paciente?"
                />

                {isNewUser && (
                  <TextField
                    fullWidth
                    type="text"
                    label="Nome da(o) Nova(o) Paciente"
                    {...getFieldProps('clientName')}
                    error={Boolean(touched.clientName && errors.clientName)}
                    helperText={touched.clientName && errors.clientName}
                  />
                )}

                <InputLabel id="simple-select-label">Sala de Atendimento:</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Acompanhado(a) por:"
                  {...getFieldProps('room_id')}
                  error={Boolean(touched.room_id && errors.room_id)}
                  helperText={touched.room_id && errors.room_id}
                >
                  {rooms.map((room) => (
                    <MenuItem key={room.id} value={room.id}>
                      {room.title}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  type="text"
                  label="Valor Acordado (R$)"
                  inputProps={{ inputMode: 'numeric' }}
                  {...getFieldProps('agreed_value')}
                  error={Boolean(touched.agreed_value && errors.agreed_value)}
                  helperText={touched.agreed_value && errors.agreed_value}
                />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                  mb={5}
                >
                  <TextField
                    sx={{ width: '50ch' }}
                    type="text"
                    label="Valor pago Dinheiro (R$)"
                    inputProps={{ inputMode: 'numeric' }}
                    {...getFieldProps('cash_value')}
                    error={Boolean(touched.cash_value && errors.cash_value)}
                    helperText={touched.cash_value && errors.cash_value}
                  />
                  <TextField
                    sx={{ width: '50ch' }}
                    type="text"
                    label="Valor Transferido (R$)"
                    inputProps={{ inputMode: 'numeric' }}
                    {...getFieldProps('value')}
                    error={Boolean(touched.transfer_value && errors.transfer_value)}
                    helperText={touched.transfer_value && errors.transfer_value}
                  />
                </Stack>

                <Typography variant="h5" gutterBottom>
                  Data e Horário:
                </Typography>
                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Data do Atendimento"
                  {...getFieldProps('date')}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                />

                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Horário Início"
                  {...getFieldProps('timeStart')}
                  error={Boolean(touched.timeStart && errors.timeStart)}
                  helperText={touched.timeStart && errors.timeStart}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Horário de Encerramento"
                  {...getFieldProps('timeEnd')}
                  error={Boolean(touched.timeEnd && errors.timeEnd)}
                  helperText={touched.timeEnd && errors.timeEnd}
                />
              </Stack>
              <Typography variant="h6" gutterBottom>
                Deseja que esse agendamento seja recorrente?
              </Typography>
              <Select
                InputLabelProps={{ shrink: true }}
                labelId="simple-select-label"
                id="simple-select"
                label="Qual recorrência?"
                {...getFieldProps('frequencyBulk')}
                error={Boolean(touched.frequencyBulk && errors.frequencyBulk)}
                helperText={touched.frequencyBulk && errors.frequencyBulk}
              >
                <MenuItem value="">Não Repetir</MenuItem>
                <MenuItem value="week">Semanalmente</MenuItem>
                <MenuItem value="2 weeks">A cada 2 semanas</MenuItem>
                <MenuItem value="month">Mensalmente</MenuItem>
              </Select>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Cadastrar Novo Agendamento
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
