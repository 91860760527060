import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@mui/material';

// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';

import api from '../../services/api';
import { formattedBirthdate } from '../_user/utils';

// ----------------------------------------------------------------------

export default function Schedule() {
  const { id } = useParams();
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiGetSchedules = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/schedules/client/${id}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setSchedules(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    apiGetSchedules();
  }, []);

  return (
    <Page title="Adendamentos | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todos os Agendamentos
          </Typography>
        </Stack>

        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Data do Agendamento</TableCell>
                    <TableCell>Valor Acordado</TableCell>
                    <TableCell>Profissional que Atendeu</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedules.map((schedule) => (
                    <TableRow key={schedule?.id} sx={{ py: 3 }}>
                      <TableCell>{formattedBirthdate(schedule?.created_at.slice(0, 10))}</TableCell>
                      <TableCell>R$ {schedule?.agreed_value}</TableCell>
                      <TableCell>{schedule?.user?.name}</TableCell>
                      <TableCell align="right">{schedule?.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
    </Page>
  );
}
