import * as React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Table, TableRow, TableBody, TableCell } from '@mui/material';

import { formattedBirthdate } from './utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 700,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

function UserDetails({ user }) {
  return (
    <div>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Nome: {user?.name}
        </Typography>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell scope="row">{user?.email}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Telefone:</TableCell>
              <TableCell scope="row">{user?.phone}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Telefone de Emergencia:</TableCell>
              <TableCell scope="row">{user?.phone_emergency}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Data de Nascimento:</TableCell>
              <TableCell scope="row">{formattedBirthdate(user?.birthdate)}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>CPF:</TableCell>
              <TableCell scope="row">{user?.cpf}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>CRP:</TableCell>
              <TableCell scope="row">{user?.crp}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Ocupação:</TableCell>
              <TableCell scope="row">{user?.occupation}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Informações de Agendamento:</TableCell>
              <TableCell scope="row">{user?.schedule_info}</TableCell>
            </TableRow>
          </TableBody>
          <p style={{ marginTop: 10, fontWeight: 'bold' }}>ENDEREÇO</p>
          <TableBody>
            <TableRow>
              <TableCell>CEP:</TableCell>
              <TableCell scope="row">{user?.cep}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Rua:</TableCell>
              <TableCell scope="row">{user?.street}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Numero:</TableCell>
              <TableCell scope="row">{user?.number}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Complemento:</TableCell>
              <TableCell scope="row">{user?.complement}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Bairro:</TableCell>
              <TableCell scope="row">{user?.district}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Cidade:</TableCell>
              <TableCell scope="row">{user?.city}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Estado:</TableCell>
              <TableCell scope="row">{user?.state}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}

UserDetails.propTypes = {
  user: PropTypes.object
};
export { UserDetails };
