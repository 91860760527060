import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import archiveFill from '@iconify/icons-eva/archive-outline';
import { Link as RouterLink } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// ----------------------------------------------------------------------

export default function ProfessionalMoreMenu(client) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = JSON.parse(localStorage.getItem('tokenID'));

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {user?.isProfessional && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/prontuario/${client.id}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Icon icon={archiveFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ver Prontuários" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
