import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Button,
  Container,
  Modal,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserMoreMenu } from '../components/_dashboard/user';
import { UserDetails } from './_user/Details';

import api from '../services/api';
import { formattedBirthdate } from './_user/utils';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const MoreDetails = forwardRef((props, ref) => <UserDetails ref={ref} {...props} />);

// ----------------------------------------------------------------------

export default function User() {
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(0);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState({});

  const handleOpenModal = (user) => {
    setModal(true);
    setInfoModal(user);
  };
  const handleCloseModal = () => setModal(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };

  const apiGetUsers = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/users`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUsers(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (update !== 0) {
      setSnack(true);
    }
    apiGetUsers();
  }, [update]);

  return (
    <Page title="Funcionários | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todos os Funcionários
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/funcionarios/criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Novo Funcionário
          </Button>
        </Stack>

        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome completo</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">CPF</TableCell>
                    <TableCell align="right">Data de Nascimento</TableCell>
                    <TableCell align="right">Área de Atuação</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.name} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row" onClick={() => handleOpenModal(user)}>
                        {user.name}
                      </TableCell>
                      <TableCell align="right">{user.email}</TableCell>
                      <TableCell align="right">{user.cpf}</TableCell>
                      <TableCell align="right">{formattedBirthdate(user.birthdate)}</TableCell>
                      <TableCell align="right">{user.occupation}</TableCell>
                      <TableCell align="right">
                        <UserMoreMenu id={user.id} setUpdate={setUpdate} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MoreDetails user={infoModal} />
      </Modal>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Usuário Deletado com Sucesso
        </Alert>
      </Snackbar>
    </Page>
  );
}
