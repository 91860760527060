import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import arrowUp from '@iconify/icons-eva/arrow-upward-fill';
import arrowDown from '@iconify/icons-eva/arrow-downward-fill';

import { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { BillMoreMenu } from '../components/_dashboard/user';

import api from '../services/api';
import { formattedBirthdate } from './_user/utils';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// ----------------------------------------------------------------------

export default function Bill() {
  const [bills, setBills] = useState([]);
  const [clients, setClients] = useState([]);
  const [update, setUpdate] = useState(0);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  const color = (receivement) => {
    if (receivement === 'Receita') {
      return <Icon icon={arrowUp} color="green" fontSize="large" />;
    }
    return <Icon icon={arrowDown} color="red" fontSize="large" />;
  };

  const status = (dueDate, payment) => {
    const start = new Date(dueDate);
    const now = new Date();

    if (!dueDate) {
      return '';
    }

    if (payment && dueDate) {
      return 'Pago';
    }

    if (!payment && now > start) {
      return 'Em Atraso';
    }

    if (!payment && now < start) {
      return 'Aberto';
    }

    return '';
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };

  const apiGetBills = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/bills`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setBills(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const isClient = (name) => {
    const hasName = clients.find((client) => client.id === name);
    return hasName ? hasName.name : name;
  };

  const apiGetClients = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/clients`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setClients(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (update !== 0) {
      setSnack(true);
    }
    apiGetBills();
    apiGetClients();
  }, [update]);

  return (
    <Page title="Contas | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todas Despesas e Receitas
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/contas/criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar Novo
          </Button>
        </Stack>

        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right"> </TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Fornecedor/Cliente</TableCell>
                    <TableCell align="right">Valor em Dinheiro</TableCell>
                    <TableCell align="right">Valor Transferido</TableCell>
                    <TableCell align="right">Data de Vencimento</TableCell>
                    <TableCell align="right">Data de Pagamento</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bills.map((bill) => (
                    <TableRow key={bill?.id} sx={{ py: 3 }}>
                      <TableCell align="right">{color(bill?.receivement)}</TableCell>
                      <TableCell component="th" scope="row">
                        {bill?.title}
                      </TableCell>
                      <TableCell align="right">{isClient(bill?.provider)}</TableCell>
                      <TableCell align="right">{bill?.cash_value}</TableCell>
                      <TableCell align="right">{bill?.transfer_value}</TableCell>
                      <TableCell align="right">{formattedBirthdate(bill?.due_date)}</TableCell>
                      <TableCell align="right">{formattedBirthdate(bill?.payment)}</TableCell>
                      <TableCell align="right">{status(bill?.due_date, bill?.payment)}</TableCell>
                      <TableCell align="right">
                        <BillMoreMenu id={bill?.id} setUpdate={setUpdate} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Conta Deletada com Sucesso
        </Alert>
      </Snackbar>
    </Page>
  );
}
