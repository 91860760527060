import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from 'react-imask';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  Input,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';

import api from '../../services/api';
import { formattedBirthdate } from '../_user/utils';

const DateMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

export default function CreateInvoice() {
  const navigate = useNavigate();
  const [clients, setClients] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const createAPI = async (values) => {
    if (values?.date) {
      values.date = formattedBirthdate(values.date);
    }
    if (values?.value) {
      values.value = values?.value.replace(',', '.');
    }

    values.status = 'Não Gerada';

    try {
      await api.post('/invoices', values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate('/dashboard/notas-fiscais', { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const apiGetClients = async () => {
    try {
      const request = await api.get(`/clients`, {
        headers: {
          Accept: 'application/json'
        }
      });
      const sortClients = request.data.sort((a, b) => a.name - b.name);

      setClients(sortClients);
    } catch (e) {
      console.error(e);
    }
  };

  const apiGetUsers = async () => {
    try {
      const request = await api.get(`/users`, {
        headers: {
          Accept: 'application/json'
        }
      });
      const sortUsers = request.data.sort((a, b) => a.name - b.name);

      setUsers(sortUsers);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    apiGetClients();
    apiGetUsers();
  }, []);

  const CreateSchema = Yup.object().shape({
    descripion: Yup.string().nullable(),
    value: Yup.string().nullable(),
    date: Yup.string().nullable(),
    user_id: Yup.string().nullable(),
    client_id: Yup.string().nullable(),
    status: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      descripion: null,
      value: null,
      date: null,
      provider: null,
      user_id: null,
      client_id: null
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      createAPI(values);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Cadastrar nova Nota Fiscal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cadastrar nova Nota Fiscal
          </Typography>
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  type="text"
                  label="Descrição"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                <InputLabel htmlFor="formatted-text-mask-input">Data da Nota Fiscal</InputLabel>
                <Input
                  fullWidth
                  type="text"
                  label="Data da Nota Fiscal"
                  {...getFieldProps('date')}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                  inputComponent={DateMaskInput}
                />

                <TextField
                  type="text"
                  label="Valor (R$)"
                  inputProps={{ inputMode: 'numeric' }}
                  {...getFieldProps('value')}
                  error={Boolean(touched.value && errors.value)}
                  helperText={touched.value && errors.value}
                />

                <InputLabel id="simple-select-label">Selecione o(a) Paciente:</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Paciente:"
                  {...getFieldProps('client_id')}
                  error={Boolean(touched.client_id && errors.client_id)}
                  helperText={touched.client_id && errors.client_id}
                >
                  {clients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel id="simple-select-label">Selecione o(a) Profissional:</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Profissional:"
                  {...getFieldProps('user_id')}
                  error={Boolean(touched.user_id && errors.user_id)}
                  helperText={touched.user_id && errors.user_id}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Cadastrar
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
