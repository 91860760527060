// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBirthDate,
  AppWeeklySales,
  AppCurrentVisits
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { user } = JSON.parse(localStorage.getItem('tokenID'));
  const isProfessional = !user.isAdmin && user.isProfessional;

  return (
    <Page title="Dashboard | Psicicologia Medica">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Olá, excelente trabalho para você!</Typography>
        </Box>
        {!isProfessional && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <AppWeeklySales />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AppNewUsers />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AppCurrentVisits />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AppBirthDate />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
