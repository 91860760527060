export const formattedBirthdate = (birthdate) => {
  if (!birthdate) {
    return birthdate;
  }
  const formatted = birthdate.split('-');
  return `${formatted[2]}-${formatted[1]}-${formatted[0]}`;
};

export const formattedDate = (date) => {
  if (!date) {
    return date;
  }
  const formatted = date.split('/');
  return `${formatted[0]}-${formatted[1]}-${formatted[2]}`;
};
