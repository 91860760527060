import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

export default function RecoveryPassword() {
  return (
    <Link underline="none" variant="subtitle2" component={RouterLink} to="/recuperar-senha">
      Esqueceu a senha?
    </Link>
  );
}
