import { useState, useEffect } from 'react';
// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Input
} from '@mui/material';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { ProfessionalMoreMenu } from '../components/_dashboard/user';

import api from '../services/api';
import { formattedBirthdate } from './_user/utils';
import { filterClients } from './_client/utils';

// ----------------------------------------------------------------------

export default function ClientProfessional() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nameClient, setNameClient] = useState('');
  const [filteredClients, setFilteredClients] = useState(clients);

  const handleFilter = () => {
    setFilteredClients(filterClients(clients, nameClient));
  };

  const apiGetClients = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/clients/me/professional`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setClients(request.data);
      setFilteredClients(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    apiGetClients();
  }, []);

  return (
    <Page title="Pacientes | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todos os Seus Pacientes
          </Typography>
        </Stack>
        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <div style={{ padding: 10, float: 'right' }}>
                <Input
                  style={{ marginRight: 10 }}
                  type="text"
                  placeholder="Nome do Paciente"
                  onChange={(e) => setNameClient(e.target.value)}
                />
                <Button variant="outlined" onClick={handleFilter}>
                  Filtrar
                </Button>
              </div>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome completo</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">CPF</TableCell>
                    <TableCell align="right">Data de Nascimento</TableCell>
                    <TableCell align="right">Telefone</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClients.map((client) => (
                    <TableRow key={client?.name} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row">
                        {client?.name}
                      </TableCell>
                      <TableCell align="right">{client?.email}</TableCell>
                      <TableCell align="right">{client?.cpf}</TableCell>
                      <TableCell align="right">{formattedBirthdate(client?.birthdate)}</TableCell>
                      <TableCell align="right">{client?.phone}</TableCell>
                      <TableCell align="right">
                        <ProfessionalMoreMenu id={client?.id} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
    </Page>
  );
}
