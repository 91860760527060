import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, Container, Typography, Card, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';

import api from '../../services/api';
import { formattedBirthdate } from '../_user/utils';

export default function EditRoom() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [room, setRoom] = React.useState();

  const getAPI = async () => {
    try {
      const response = await api.get(`/rooms/${id}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      setRoom(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const createAPI = async (values) => {
    values.birthdate = formattedBirthdate(values.birthdate);
    try {
      await api.put(`/rooms/${id}`, values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate('/dashboard/salas', { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const CreateSchema = Yup.object().shape({
    title: Yup.string().nullable(),
    description: Yup.string().nullable()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: room?.title,
      description: room?.description
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      createAPI(values);
    }
  });

  React.useEffect(() => {
    getAPI();
  }, []);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Editar Sala">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Editar Sala
          </Typography>
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações de Principais:
                </Typography>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Nome da Sala"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Descrição"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Editar Sala
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
