import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Button,
  Container,
  Modal,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Snackbar,
  Input
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { ClientMoreMenu } from '../components/_dashboard/user';
import { ClientDetails } from './_client/Details';

import api from '../services/api';
import { formattedBirthdate } from './_user/utils';
import { filterClients } from './_client/utils';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const MoreDetails = forwardRef((props, ref) => <ClientDetails ref={ref} {...props} />);

// ----------------------------------------------------------------------

export default function Client() {
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(0);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState({});
  const [nameClient, setNameClient] = useState('');
  const [filteredClients, setFilteredClients] = useState(clients);

  const handleOpenModal = (client) => {
    setModal(true);
    setInfoModal(client);
  };
  const handleCloseModal = () => setModal(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };

  const handleFilter = () => {
    setFilteredClients(filterClients(clients, nameClient));
  };

  const apiGetClients = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/clients`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setClients(request.data);
      setFilteredClients(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const apiGetUsers = async () => {
    try {
      const request = await api.get(`/users`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUsers(request.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (update !== 0) {
      setSnack(true);
    }
    apiGetClients();
    apiGetUsers();
  }, [update]);

  return (
    <Page title="Pacientes | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todos os Pacientes
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/pacientes/criar"
            startIcon={<Icon icon={plusFill} />}
          >
            Novo Paciente
          </Button>
        </Stack>
        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <div style={{ padding: 10, float: 'right' }}>
                <Input
                  style={{ marginRight: 10 }}
                  type="text"
                  placeholder="Nome do Paciente"
                  onChange={(e) => setNameClient(e.target.value)}
                />
                <Button variant="outlined" onClick={handleFilter}>
                  Filtrar
                </Button>
              </div>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome completo</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">CPF</TableCell>
                    <TableCell align="right">Data de Nascimento</TableCell>
                    <TableCell align="right">Telefone</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClients.map((client) => (
                    <TableRow key={client?.name} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row" onClick={() => handleOpenModal(client)}>
                        {client?.name}
                      </TableCell>
                      <TableCell align="right">{client?.email}</TableCell>
                      <TableCell align="right">{client?.cpf}</TableCell>
                      <TableCell align="right">{formattedBirthdate(client?.birthdate)}</TableCell>
                      <TableCell align="right">{client?.phone}</TableCell>
                      <TableCell align="right">
                        <ClientMoreMenu id={client?.id} setUpdate={setUpdate} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MoreDetails client={infoModal} users={users} />
      </Modal>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Paciente Deletado com Sucesso
        </Alert>
      </Snackbar>
    </Page>
  );
}
