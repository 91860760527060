import * as React from 'react';
import * as Yup from 'yup';
import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';
import { IMaskInput } from 'react-imask';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  Input,
  InputLabel,
  MenuItem,
  Grid,
  Select
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';

import api from '../../services/api';
import { formattedBirthdate } from '../_user/utils';

const BirthdateMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

const FirstVisitMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

const CpfMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000.000.000-00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

const PhoneMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

const CEPMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000-000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

const CEPFiscalMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000-000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

export default function EditClient() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [state, setState] = React.useState('');
  const [fiscalState, setFiscalState] = React.useState('');
  const [professional, setProfessional] = React.useState('');
  const [professional2, setProfessional2] = React.useState('');
  const [professional3, setProfessional3] = React.useState('');

  const getAPI = async () => {
    try {
      const response = await api.get(`/clients/${id}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      setClients(response.data);
      setProfessional(response.data.user_id);
      setProfessional2(response.data.user2_id);
      setProfessional3(response.data.user3_id);
      setState(response.data.state);
      setFiscalState(response.data.fiscal_state);
    } catch (e) {
      console.error(e);
    }
  };

  const createAPI = async (values) => {
    values.birthdate = formattedBirthdate(values.birthdate);
    try {
      await api.put(`/clients/${id}`, values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate('/dashboard/pacientes', { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const CreateSchema = Yup.object().shape({
    email: Yup.string().email('O e-mail precisa ser válido').nullable(),
    name: Yup.string().nullable(),
    cpf: Yup.string().nullable(),
    occupation: Yup.string().nullable(),
    contact_parents: Yup.string().nullable(),
    address_info: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    contact_emergency: Yup.string().nullable(),
    contact_emergency2: Yup.string().nullable(),
    user_id: Yup.string().nullable(),
    user2_id: Yup.string().nullable(),
    user3_id: Yup.string().nullable(),
    cep: Yup.string().nullable(),
    street: Yup.string().nullable(),
    district: Yup.string().nullable(),
    number: Yup.string().nullable(),
    complement: Yup.string().nullable(),
    state: Yup.string().nullable(),
    city: Yup.string().nullable(),
    first_visit: Yup.string().nullable(),
    fiscal_cep: Yup.string().nullable(),
    fiscal_street: Yup.string().nullable(),
    fiscal_district: Yup.string().nullable(),
    fiscal_number: Yup.string().nullable(),
    fiscal_complement: Yup.string().nullable(),
    fiscal_state: Yup.string().nullable(),
    fiscal_city: Yup.string().nullable(),
    contact_father_name: Yup.string().nullable(),
    contact_father_number: Yup.string().nullable(),
    contact_mother_name: Yup.string().nullable(),
    contact_mother_number: Yup.string().nullable(),
    contact_other_name: Yup.string().nullable(),
    contact_other_number: Yup.string().nullable()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: clients?.name,
      email: clients?.email,
      birthdate: formattedBirthdate(clients?.birthdate),
      cpf: clients?.cpf,
      occupation: clients?.occupation,
      address_info: clients?.address_info,
      phone: clients?.phone,
      contact_parents: clients?.contact_parents,
      contact_emergency: clients?.contact_emergency,
      contact_emergency2: clients?.contact_emergency2,
      user_id: professional,
      user2_id: professional2,
      user3_id: professional3,
      cep: clients?.cep,
      street: clients?.street,
      district: clients?.district,
      number: clients?.number,
      complement: clients?.complement,
      state,
      city: clients?.city,
      first_visit: clients?.first_visit,
      fiscal_cep: clients?.fiscal_cep,
      fiscal_street: clients?.fiscal_street,
      fiscal_district: clients?.fiscal_district,
      fiscal_number: clients?.fiscal_number,
      fiscal_complement: clients?.fiscal_complement,
      fiscal_state: fiscalState,
      fiscal_city: clients?.fiscal_city,
      fiscal_name: clients?.fiscal_name,
      fiscal_document: clients?.fiscal_document,
      contact_father_name: clients?.contact_father_name,
      contact_father_number: clients?.contact_father_number,
      contact_mother_name: clients?.contact_mother_name,
      contact_mother_number: clients?.contact_mother_number,
      contact_other_name: clients?.contact_other_name,
      contact_other_number: clients?.contact_other_number
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      createAPI(values);
    }
  });

  const apiGetUsers = async () => {
    getAPI();
    try {
      const request = await api.get(`/users`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUsers(request.data);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    apiGetUsers();
  }, []);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getCEP = (e) => {
    if (e.length > 8) {
      axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${e.replace('-', '')}/json/`
      }).then(({ data }) => {
        formik.setFieldValue('street', data?.logradouro);
        formik.setFieldValue('district', data?.bairro);
        formik.setFieldValue('city', data?.localidade);
        formik.setFieldValue('state', data?.uf);
      });
    }
  };

  const getFiscalCEP = (e) => {
    if (e.length > 8) {
      axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${e.replace('-', '')}/json/`
      }).then(({ data }) => {
        formik.setFieldValue('fiscal_street', data?.logradouro);
        formik.setFieldValue('fiscal_district', data?.bairro);
        formik.setFieldValue('fiscal_city', data?.localidade);
        formik.setFieldValue('fiscal_state', data?.uf);
      });
    }
  };

  return (
    <Page title="Cadastrar novo Paciente">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Criar Novo Paciente
          </Typography>
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações de Principais:
                </Typography>

                <Grid container>
                  <Grid item md={8}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      sx={{ pr: 2 }}
                      fullWidth
                      type="text"
                      label="Nome"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <>
                      <InputLabel htmlFor="formatted-text-first-visit-input">
                        Primeiro Atendimento
                      </InputLabel>
                      <Input
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="text"
                        label="Data de Nascimento"
                        {...getFieldProps('first_visit')}
                        error={Boolean(touched.first_visit && errors.first_visit)}
                        helperText={touched.first_visit && errors.first_visit}
                        inputComponent={FirstVisitMaskInput}
                      />
                    </>
                  </Grid>
                </Grid>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="email"
                  label="E-mail"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <InputLabel htmlFor="formatted-text-mask-input">Telefone</InputLabel>
                <Input
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Telefone"
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  inputComponent={PhoneMaskInput}
                />

                <Typography variant="h5" gutterBottom>
                  Documentação:
                </Typography>

                <InputLabel htmlFor="formatted-text-mask-input">Data de Nascimento</InputLabel>
                <Input
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Data de Nascimento"
                  {...getFieldProps('birthdate')}
                  error={Boolean(touched.birthdate && errors.birthdate)}
                  helperText={touched.birthdate && errors.birthdate}
                  inputComponent={BirthdateMaskInput}
                />

                <InputLabel htmlFor="formatted-text-mask-input">Número do CPF</InputLabel>
                <Input
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Número do CPF"
                  {...getFieldProps('cpf')}
                  error={Boolean(touched.cpf && errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                  inputComponent={CpfMaskInput}
                />

                <Typography variant="h5" gutterBottom>
                  Informações complementares:
                </Typography>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Ocupação"
                  {...getFieldProps('occupation')}
                  error={Boolean(touched.occupation && errors.occupation)}
                  helperText={touched.occupation && errors.occupation}
                />

                <Typography variant="h5" gutterBottom>
                  Endereço:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <InputLabel htmlFor="formatted-text-mask-input">CEP:</InputLabel>
                    <Input
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="CEP"
                      {...getFieldProps('cep')}
                      error={Boolean(touched.cep && errors.cep)}
                      helperText={touched.cep && errors.cep}
                      inputComponent={CEPMaskInput}
                      inputProps={{
                        onBlur: (e) => getCEP(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Rua"
                      {...getFieldProps('street')}
                      error={Boolean(touched.street && errors.street)}
                      helperText={touched.street && errors.street}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Número"
                      {...getFieldProps('number')}
                      error={Boolean(touched.number && errors.number)}
                      helperText={touched.number && errors.number}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Complemento"
                      {...getFieldProps('complement')}
                      error={Boolean(touched.complement && errors.complement)}
                      helperText={touched.complement && errors.complement}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Bairro"
                      {...getFieldProps('district')}
                      error={Boolean(touched.district && errors.district)}
                      helperText={touched.district && errors.district}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Cidade"
                      {...getFieldProps('city')}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <InputLabel id="simple-select-label">Estado:</InputLabel>
                    <Select
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      labelId="simple-select-label"
                      id="simple-select"
                      label="Estado:"
                      {...getFieldProps('state')}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                    >
                      <MenuItem value="AC">Acre</MenuItem>
                      <MenuItem value="AL">Alagoas</MenuItem>
                      <MenuItem value="AP">Amapá</MenuItem>
                      <MenuItem value="AM">Amazonas</MenuItem>
                      <MenuItem value="BA">Bahia</MenuItem>
                      <MenuItem value="CE">Ceará</MenuItem>
                      <MenuItem value="DF">Distrito Federal</MenuItem>
                      <MenuItem value="ES">Espírito Santo</MenuItem>
                      <MenuItem value="GO">Goiás</MenuItem>
                      <MenuItem value="MT">Mato Grosso</MenuItem>
                      <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                      <MenuItem value="MG">Minas Gerais</MenuItem>
                      <MenuItem value="PA">Pará</MenuItem>
                      <MenuItem value="PB">Paraíba</MenuItem>
                      <MenuItem value="PR">Paraná</MenuItem>
                      <MenuItem value="PE">Pernambuco</MenuItem>
                      <MenuItem value="PI">Piauí</MenuItem>
                      <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                      <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                      <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                      <MenuItem value="RO">Rondônia</MenuItem>
                      <MenuItem value="RR">Roraima</MenuItem>
                      <MenuItem value="SC">Santa Catarina</MenuItem>
                      <MenuItem value="SP">São Paulo</MenuItem>
                      <MenuItem value="DF">Distrito</MenuItem>
                      <MenuItem value="SE">Sergipe</MenuItem>
                      <MenuItem value="TO">Tocantins</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Typography variant="h5" gutterBottom>
                  Contatos:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Contato da Mãe"
                      {...getFieldProps('contact_mother_name')}
                      error={Boolean(touched.contact_mother_name && errors.contact_mother_name)}
                      helperText={touched.contact_mother_name && errors.contact_mother_name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Telefone da Mãe"
                      {...getFieldProps('contact_mother_number')}
                      error={Boolean(touched.contact_mother_number && errors.contact_mother_number)}
                      helperText={touched.contact_mother_number && errors.contact_mother_number}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Contato do Pai"
                      {...getFieldProps('contact_father_name')}
                      error={Boolean(touched.contact_father_name && errors.contact_father_name)}
                      helperText={touched.contact_father_name && errors.contact_father_name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Telefone do Pai"
                      {...getFieldProps('contact_father_number')}
                      error={Boolean(touched.contact_father_number && errors.contact_father_number)}
                      helperText={touched.contact_father_number && errors.contact_father_number}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Outro Contato"
                      {...getFieldProps('contact_other_name')}
                      error={Boolean(touched.contact_other_name && errors.contact_other_name)}
                      helperText={touched.contact_other_name && errors.contact_other_name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Telefone do Outro Contato"
                      {...getFieldProps('contact_other_number')}
                      error={Boolean(touched.contact_other_number && errors.contact_other_number)}
                      helperText={touched.contact_other_number && errors.contact_other_number}
                    />
                  </Grid>
                </Grid>

                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Contato de Emergência"
                  {...getFieldProps('contact_emergency')}
                  error={Boolean(touched.contact_emergency && errors.contact_emergency)}
                  helperText={touched.contact_emergency && errors.contact_emergency}
                />

                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Contato de Emergência(2)"
                  {...getFieldProps('contact_emergency2')}
                  error={Boolean(touched.contact_emergency2 && errors.contact_emergency2)}
                  helperText={touched.contact_emergency2 && errors.contact_emergency2}
                />

                <Typography variant="h5" gutterBottom>
                  Acompanhamento:
                </Typography>

                <InputLabel id="simple-select-label">Acompanhado(a) pelo Profissional:</InputLabel>
                <TextField
                  select
                  InputLabelProps={{ shrink: true }}
                  labelId="simple-select-label"
                  {...getFieldProps('user_id')}
                  error={Boolean(touched.user_id && errors.user_id)}
                  helperText={touched.user_id && errors.user_id}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </TextField>

                <InputLabel id="simple-select-label">
                  Acompanhado(a) tambem pelo Profissional:
                </InputLabel>
                <TextField
                  select
                  InputLabelProps={{ shrink: true }}
                  labelId="simple-select-label"
                  {...getFieldProps('user2_id')}
                  error={Boolean(touched.user2_id && errors.user2_id)}
                  helperText={touched.user2_id && errors.user2_id}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </TextField>

                <InputLabel id="simple-select-label">
                  Acompanhado(a) tambem pelo Profissional:
                </InputLabel>
                <TextField
                  select
                  InputLabelProps={{ shrink: true }}
                  labelId="simple-select-label"
                  {...getFieldProps('user3_id')}
                  error={Boolean(touched.user3_id && errors.user3_id)}
                  helperText={touched.user3_id && errors.user3_id}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </TextField>

                <Typography variant="h5" gutterBottom>
                  Dados Fiscais:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Nome do Responsável Fiscal"
                      {...getFieldProps('fiscal_name')}
                      error={Boolean(touched.fiscal_name && errors.fiscal_name)}
                      helperText={touched.fiscal_name && errors.fiscal_name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="CPF/CNPJ do Responsável Fiscal"
                      {...getFieldProps('fiscal_document')}
                      error={Boolean(touched.fiscal_document && errors.fiscal_document)}
                      helperText={touched.fiscal_document && errors.fiscal_document}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <InputLabel htmlFor="formatted-text-mask-input">CEP:</InputLabel>
                    <Input
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="CEP"
                      {...getFieldProps('fiscal_cep')}
                      error={Boolean(touched.fiscal_cep && errors.fiscal_cep)}
                      helperText={touched.fiscal_cep && errors.fiscal_cep}
                      inputComponent={CEPFiscalMaskInput}
                      inputProps={{
                        onBlur: (e) => getFiscalCEP(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Rua"
                      {...getFieldProps('fiscal_street')}
                      error={Boolean(touched.fiscal_street && errors.fiscal_street)}
                      helperText={touched.fiscal_street && errors.fiscal_street}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Número"
                      {...getFieldProps('fiscal_number')}
                      error={Boolean(touched.fiscal_number && errors.fiscal_number)}
                      helperText={touched.fiscal_number && errors.fiscal_number}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="text"
                      label="Complemento"
                      {...getFieldProps('fiscal_complement')}
                      error={Boolean(touched.fiscal_complement && errors.fiscal_complement)}
                      helperText={touched.fiscal_complement && errors.fiscal_complement}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Bairro"
                      {...getFieldProps('fiscal_district')}
                      error={Boolean(touched.fiscal_district && errors.fiscal_district)}
                      helperText={touched.fiscal_district && errors.fiscal_district}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Cidade"
                      {...getFieldProps('fiscal_city')}
                      error={Boolean(touched.fiscal_city && errors.fiscal_city)}
                      helperText={touched.fiscal_city && errors.fiscal_city}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <InputLabel id="simple-select-label">Estado:</InputLabel>
                    <Select
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      labelId="simple-select-label"
                      id="simple-select"
                      label="Estado:"
                      {...getFieldProps('fiscal_state')}
                      error={Boolean(touched.fiscal_state && errors.fiscal_state)}
                      helperText={touched.fiscal_state && errors.fiscal_state}
                    >
                      <MenuItem value="AC">Acre</MenuItem>
                      <MenuItem value="AL">Alagoas</MenuItem>
                      <MenuItem value="AP">Amapá</MenuItem>
                      <MenuItem value="AM">Amazonas</MenuItem>
                      <MenuItem value="BA">Bahia</MenuItem>
                      <MenuItem value="CE">Ceará</MenuItem>
                      <MenuItem value="DF">Distrito Federal</MenuItem>
                      <MenuItem value="ES">Espírito Santo</MenuItem>
                      <MenuItem value="GO">Goiás</MenuItem>
                      <MenuItem value="MT">Mato Grosso</MenuItem>
                      <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                      <MenuItem value="MG">Minas Gerais</MenuItem>
                      <MenuItem value="PA">Pará</MenuItem>
                      <MenuItem value="PB">Paraíba</MenuItem>
                      <MenuItem value="PR">Paraná</MenuItem>
                      <MenuItem value="PE">Pernambuco</MenuItem>
                      <MenuItem value="PI">Piauí</MenuItem>
                      <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                      <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                      <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                      <MenuItem value="RO">Rondônia</MenuItem>
                      <MenuItem value="RR">Roraima</MenuItem>
                      <MenuItem value="SC">Santa Catarina</MenuItem>
                      <MenuItem value="SP">São Paulo</MenuItem>
                      <MenuItem value="DF">Distrito</MenuItem>
                      <MenuItem value="SE">Sergipe</MenuItem>
                      <MenuItem value="TO">Tocantins</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Editar Paciente
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
