// ----------------------------------------------------------------------
const user = JSON.parse(localStorage.getItem('tokenID'));

const account = {
  displayName: user?.user?.name,
  email: user?.user?.email,
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

export default account;
