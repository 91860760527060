import api from './api';

const logoutUser = () => {
  localStorage.removeItem('tokenID');
  window.location.href = '/';
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logoutUser();
    }
    throw error;
  }
);
