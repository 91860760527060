import { useState, useEffect } from 'react';

// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button
} from '@mui/material';
import { formatDateYYYYMMDD } from '../utils/formatTime';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { FilterForm } from './_filterSchedule/FilterForm';

import api from '../services/api';
import { formattedBirthdate } from './_user/utils';

// ----------------------------------------------------------------------

export default function FilterInvoices() {
  const { dateStart, dateEnd } = formatDateYYYYMMDD();

  const [invoices, setInvoices] = useState([]);
  const [update, setUpdate] = useState(true);
  const [sum, setSum] = useState(0);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ dateStart, dateEnd });

  const getClients = async () => {
    const request = await api.get(`/clients`, {
      headers: {
        Accept: 'application/json'
      }
    });

    setClients(request.data);
  };

  const updateInvoice = async (id) => {
    const value = prompt('Insira o número da nota:');

    if (value) {
      const payload = {
        invoice_number: value
      };

      try {
        await api.put(`/schedules-invoice/${id}`, payload, {
          headers: {
            Accept: 'application/json'
          }
        });
        setUpdate(!update);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getUsers = async () => {
    const request = await api.get(`/users`, {
      headers: {
        Accept: 'application/json'
      }
    });

    setUsers(request.data);
  };

  useEffect(() => {
    getClients();
    getUsers();
  }, []);

  const apiGetSchedules = async () => {
    try {
      setLoading(true);
      const request = await api.post(`/schedules-invoice/list`, filter, {
        headers: {
          Accept: 'application/json'
        }
      });

      setInvoices(request.data.data);
      setSum(request.data.sum);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    apiGetSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, update]);

  return (
    <Page title="Notas Fiscais | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todas as Notas Fiscais
          </Typography>
        </Stack>
        <Card sx={{ margin: '15px 0', padding: '10px' }}>
          <FilterForm
            setFilter={setFilter}
            filter={filter}
            clients={clients}
            users={users}
            isShedule={false}
          />
        </Card>
        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Data da Consulta</TableCell>
                    <TableCell>Valor Combinado</TableCell>
                    <TableCell>Paciente</TableCell>
                    <TableCell>Profissional</TableCell>
                    <TableCell>Nº Nota</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices.map((invoice) => (
                    <TableRow key={invoice?.id} sx={{ py: 3 }}>
                      <TableCell>
                        {formattedBirthdate(invoice?.schedule?.start_datetime.substring(0, 10))}
                      </TableCell>
                      <TableCell>{invoice?.schedule?.agreed_value}</TableCell>
                      <TableCell>{invoice?.schedule?.client?.name}</TableCell>
                      <TableCell>{invoice?.schedule?.user?.name}</TableCell>
                      <TableCell>
                        {invoice?.invoice_number ? (
                          invoice.invoice_number
                        ) : (
                          <Button onClick={() => updateInvoice(invoice?.id)}>Gerar Nota</Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ py: 3, background: '#d3ffd3' }}>
                    <TableCell component="th" scope="row" c>
                      TOTAL:
                    </TableCell>
                    <TableCell>R$: {sum.toFixed(2)}</TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
    </Page>
  );
}
