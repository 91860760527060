import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  InputLabel,
  Select,
  Button,
  MenuItem
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { formattedBirthdate } from '../_user/utils';

import api from '../../services/api';

export default function EditSchedule() {
  const navigate = useNavigate();
  const { user, scheduleId } = useParams();
  const [clients, setClients] = React.useState([]);
  const [schedule, setSchedule] = React.useState([]);
  const [profile, setProfile] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);

  const [clientId, setClientId] = React.useState('');
  const [roomId, setRoomId] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [dataFormatted, setDataFormatted] = React.useState({});

  const getCorrectDate = (date) => {
    const today = new Date(date);
    if (window.location.origin === 'https://admin.psicologiamedica.com.br') {
      today.setHours(today.getHours() + 3);
    }
    const hour = today.getHours();
    const minutes = today.getMinutes();

    return {
      date: today.toISOString().split('T')[0],
      time: `${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`
    };
  };

  const apiGetClients = async () => {
    try {
      const request = await api.get(`/clients`, {
        headers: {
          Accept: 'application/json'
        }
      });
      const sortClients = request.data.sort((a, b) => a.name - b.name);

      setClients(sortClients);
    } catch (e) {
      console.error(e);
    }
  };

  const getAPI = async () => {
    try {
      const response = await api.get(`/users/${user}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      const schedule = await api.get(`/schedules/${scheduleId}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      setSchedule(schedule.data);
      setStatus(schedule.data.status);
      setClientId(schedule.data.client_id);
      setRoomId(schedule.data.room_id);
      setProfile(response.data);
      setDataFormatted({
        start: getCorrectDate(schedule.data.start_datetime),
        end: getCorrectDate(schedule.data.end_datetime)
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRooms = async () => {
    try {
      const request = await api.get(`/rooms`, {
        headers: {
          Accept: 'application/json'
        }
      });
      const sortRooms = request.data.sort((a, b) => a.title - b.title);

      setRooms(sortRooms);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    apiGetClients();
    getAPI();
    getRooms();
  }, []);

  const formatredValue = (value) => {
    value = value.replace(',', '.');
    value = value.split('.');
    let returValue = value[0];
    if (value[1]) {
      returValue = `${value[0]}.${value[1].slice(0, 2)}`;
    }
    return returValue;
  };

  const createAPI = async (values) => {
    if (values.agreed_value) values.agreed_value = formatredValue(values.agreed_value);
    if (values.cash_value) values.cash_value = formatredValue(values.cash_value);
    if (values.transfer_value) values.transfer_value = formatredValue(values.transfer_value);
    values.start_datetime = `${formattedBirthdate(values.date)} ${values.timeStart}`;
    values.end_datetime = `${formattedBirthdate(values.date)} ${values.timeEnd}`;
    values.user_id = user;
    try {
      await api.put(`/schedules/${scheduleId}`, values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate(`/dashboard/agendamento/${user}`, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const CreateSchema = Yup.object().shape({
    timeStart: Yup.string().required('Horário é obrigatório'),
    timeEnd: Yup.string().required('Horário é obrigatório')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      client_id: clientId,
      room_id: roomId,
      agreed_value: schedule?.agreed_value,
      cash_value: schedule?.cash_value,
      transfer_value: schedule?.transfer_value,
      date: formattedBirthdate(dataFormatted?.start?.date),
      timeStart: dataFormatted?.start?.time,
      timeEnd: dataFormatted?.end?.time,
      status
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      createAPI(values);
    }
  });

  const DeleteApi = async () => {
    if (window.confirm('Deseja excluir este agendamento?')) {
      try {
        await api.delete(`/schedules/${scheduleId}`, {
          headers: {
            Accept: 'application/json'
          }
        });
        navigate(`/dashboard/agendamento/${user}`, { replace: true });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const DeleteBulkApi = async () => {
    if (
      window.confirm(
        'Atenção!!!! Deseja excluir todos os agendamentos que foram criados em massa para este paciente?'
      )
    ) {
      try {
        await api.delete(`/schedules/bulk/${user}/${clientId}`, {
          headers: {
            Accept: 'application/json'
          }
        });
        navigate(`/dashboard/agendamento/${user}`, { replace: true });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Editar Agendamento">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Editar Agendamento - {profile?.name}
          </Typography>
          <Button onClick={DeleteApi} variant="contained" color="error">
            Deletar Agendamento
          </Button>
          {schedule.is_bulk && schedule.status === 'Não Pago' && (
            <Button onClick={DeleteBulkApi} variant="contained" color="warning">
              Deletar Todos Agendamentos Recorrentes
            </Button>
          )}
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações do Agendamento
                </Typography>

                <InputLabel id="simple-select-label">Agendamento para o(a) paciente:</InputLabel>
                <TextField
                  select
                  InputLabelProps={{ shrink: true }}
                  disabled
                  label="Selecione o Paciente"
                  labelId="simple-select-label"
                  {...getFieldProps('client_id')}
                  error={Boolean(touched.client_id && errors.client_id)}
                  helperText={touched.client_id && errors.client_id}
                >
                  {clients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </TextField>

                <InputLabel id="simple-select-label">Sala de Atendimento:</InputLabel>
                <TextField
                  select
                  InputLabelProps={{ shrink: true }}
                  label="Sala de Atendimento:"
                  labelId="simple-select-label"
                  {...getFieldProps('room_id')}
                  error={Boolean(touched.room_id && errors.room_id)}
                  helperText={touched.room_id && errors.room_id}
                >
                  {rooms.map((room) => (
                    <MenuItem key={room.id} value={room.id}>
                      {room.title}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Valor Acordado (R$)"
                  inputProps={{ inputMode: 'numeric' }}
                  {...getFieldProps('agreed_value')}
                  error={Boolean(touched.agreed_value && errors.agreed_value)}
                  helperText={touched.agreed_value && errors.agreed_value}
                />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                  mb={5}
                >
                  <TextField
                    sx={{ width: '50ch' }}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Valor pago Dinheiro (R$)"
                    inputProps={{ inputMode: 'numeric' }}
                    {...getFieldProps('cash_value')}
                    error={Boolean(touched.cash_value && errors.cash_value)}
                    helperText={touched.cash_value && errors.cash_value}
                  />
                  <TextField
                    sx={{ width: '50ch' }}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Valor Transferido (R$)"
                    inputProps={{ inputMode: 'numeric' }}
                    {...getFieldProps('transfer_value')}
                    error={Boolean(touched.transfer_value && errors.transfer_value)}
                    helperText={touched.transfer_value && errors.transfer_value}
                  />
                </Stack>
                <Select
                  InputLabelProps={{ shrink: true }}
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Status do Agendamento"
                  {...getFieldProps('status')}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="Não Pago">Não Pago</MenuItem>
                  <MenuItem value="Pagamento Pendente">Pagamento Pendente</MenuItem>
                  <MenuItem value="Pago">Pago</MenuItem>
                  <MenuItem value="Não Compareceu">Não Compareceu</MenuItem>
                  <MenuItem value="Isento">Isento</MenuItem>
                </Select>

                <Typography variant="h5" gutterBottom>
                  Data e Horário:
                </Typography>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Data do Atendimento"
                  disabled
                  {...getFieldProps('date')}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                />

                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Horário Início"
                  {...getFieldProps('timeStart')}
                  error={Boolean(touched.timeStart && errors.timeStart)}
                  helperText={touched.timeStart && errors.timeStart}
                />

                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Horário de Encerramento"
                  {...getFieldProps('timeEnd')}
                  error={Boolean(touched.timeEnd && errors.timeEnd)}
                  helperText={touched.timeEnd && errors.timeEnd}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Editar Agendamento
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
