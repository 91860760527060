import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from 'react-imask';

import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, Container, Typography, Card, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';

import api from '../../services/api';

export default function CreateRoom() {
  const navigate = useNavigate();
  const createAPI = async (values) => {
    try {
      await api.post('/rooms', values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate('/dashboard/salas', { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const CreateSchema = Yup.object().shape({
    title: Yup.string().nullable(),
    description: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      title: null,
      description: null
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      createAPI(values);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Cadastrar nova Sala">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Criar Nova Sala
          </Typography>
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações da Sala:
                </Typography>

                <TextField
                  fullWidth
                  type="text"
                  label="Nome da Sala"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Descrição"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Cadastrar Novo Sala
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
