import axios from 'axios';

const storage = JSON.parse(localStorage.getItem('tokenID'));

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: 'https://pscicologiamedica.alvim.dev.br',
  headers: { Authorization: `Bearer ${storage?.token}` }
});

export default api;
