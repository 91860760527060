import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Stack
} from '@mui/material';

import { formattedBirthdate } from '../_user/utils';

const style = {
  position: 'absolute',
  maxHeight: '90vh',
  overflowY: 'scroll',
  top: '50%',
  left: '50%',
  width: 700,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

function ClientDetails({ client, users }) {
  const isUser = (name) => {
    const hasName = users.find((user) => user.id === name);
    return hasName ? hasName.name : name;
  };

  return (
    <div>
      <Box sx={style}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nome: {client?.name}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            target="_blank"
            to={`/dashboard/pacientes/imprimir/${client.id}`}
          >
            Imprimir
          </Button>
        </Stack>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell>Primeiro Atendimento:</TableCell>
              <TableCell scope="row">{client?.first_visit}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Telefone:</TableCell>
              <TableCell scope="row">{client?.phone}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Data de Nascimento:</TableCell>
              <TableCell scope="row">{formattedBirthdate(client?.birthdate)}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>CPF:</TableCell>
              <TableCell scope="row">{client?.cpf}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Ocupação:</TableCell>
              <TableCell scope="row">{client?.occupation}</TableCell>
            </TableRow>
          </TableBody>

          <p style={{ marginTop: 10, fontWeight: 'bold' }}>ENDEREÇO</p>
          <TableBody>
            <TableRow>
              <TableCell>CEP:</TableCell>
              <TableCell scope="row">{client?.cep}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Rua:</TableCell>
              <TableCell scope="row">{client?.street}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Numero:</TableCell>
              <TableCell scope="row">{client?.number}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Complemento:</TableCell>
              <TableCell scope="row">{client?.complement}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Bairro:</TableCell>
              <TableCell scope="row">{client?.district}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Cidade:</TableCell>
              <TableCell scope="row">{client?.city}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Estado:</TableCell>
              <TableCell scope="row">{client?.state}</TableCell>
            </TableRow>
          </TableBody>
          <p style={{ marginTop: 10, fontWeight: 'bold' }}>ACOMPANHAMENTO</p>
          <TableBody>
            <TableRow>
              <TableCell>Acompanhado(a) pelo Profissional:</TableCell>
              <TableCell scope="row">{isUser(client?.user_id)}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Acompanhado(a) tambem pelo Profissional:</TableCell>
              <TableCell scope="row">{isUser(client?.user2_id)}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Acompanhado(a) tambem pelo Profissional:</TableCell>
              <TableCell scope="row">{isUser(client?.user3_id)}</TableCell>
            </TableRow>
          </TableBody>

          <p style={{ marginTop: 10, fontWeight: 'bold' }}>CONTATOS</p>
          <TableBody>
            <TableRow>
              <TableCell>Contato da Mãe:</TableCell>
              <TableCell scope="row">{client?.contact_mother_name}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Telefone da Mãe:</TableCell>
              <TableCell scope="row">{client?.contact_mother_number}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Contato do Pai:</TableCell>
              <TableCell scope="row">{client?.contact_father_name}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Telefone do Pai:</TableCell>
              <TableCell scope="row">{client?.contact_father_number}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Outro Contato:</TableCell>
              <TableCell scope="row">{client?.contact_other_name}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Telefone do Outro Contato:</TableCell>
              <TableCell scope="row">{client?.contact_other_number}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Contato de Emergência:</TableCell>
              <TableCell scope="row">{client?.contact_emergency}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Contato de Emergência 2:</TableCell>
              <TableCell scope="row">{client?.contact_emergency2}</TableCell>
            </TableRow>
          </TableBody>

          <p style={{ marginTop: 10, fontWeight: 'bold' }}>DADOS FISCAIS</p>
          <TableBody>
            <TableRow>
              <TableCell>Nome Responsável Fiscal:</TableCell>
              <TableCell scope="row">{client?.fiscal_name}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Documento do Responsável Fiscal:</TableCell>
              <TableCell scope="row">{client?.fiscal_document}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>CEP:</TableCell>
              <TableCell scope="row">{client?.fiscal_cep}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Rua:</TableCell>
              <TableCell scope="row">{client?.fiscal_street}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Numero:</TableCell>
              <TableCell scope="row">{client?.fiscal_number}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Complemento:</TableCell>
              <TableCell scope="row">{client?.fiscal_complement}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Bairro:</TableCell>
              <TableCell scope="row">{client?.fiscal_district}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Cidade:</TableCell>
              <TableCell scope="row">{client?.fiscal_city}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Estado:</TableCell>
              <TableCell scope="row">{client?.fiscal_state}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}

ClientDetails.propTypes = {
  client: PropTypes.object,
  users: PropTypes.object
};
export { ClientDetails };
