import * as React from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { IMaskInput } from 'react-imask';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  Input,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';

import api from '../../services/api';
import { formattedBirthdate } from '../_user/utils';

const DateMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

export default function EditBill() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [bill, setBill] = React.useState({});
  const [type, setType] = React.useState('');
  const [receivement, setReceivement] = React.useState('');

  const getAPI = async () => {
    try {
      const response = await api.get(`/bills/${id}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      setBill(response.data);
      setType(response.data.type);
      setReceivement(response.data.receivement);
    } catch (e) {
      console.error(e);
    }
  };

  const createAPI = async (values) => {
    if (values?.due_date) {
      values.due_date = formattedBirthdate(values.due_date);
    }

    if (values?.payment) {
      values.payment = formattedBirthdate(values.payment);
    }

    if (values?.cash_value) {
      values.cash_value = values?.cash_value.replace(',', '.');
    }

    if (values?.transfer_value) {
      values.transfer_value = values?.transfer_value.replace(',', '.');
    }

    try {
      await api.put(`/bills/${id}`, values, {
        headers: {
          Accept: 'application/json'
        }
      });

      navigate('/dashboard/contas', { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    getAPI();
  }, []);

  const CreateSchema = Yup.object().shape({
    receivement: Yup.string().nullable(),
    title: Yup.string().nullable(),
    due_date: Yup.string().nullable(),
    provider: Yup.string().nullable(),
    type: Yup.string().nullable(),
    value: Yup.string().nullable(),
    payment: Yup.string().nullable(),
    obs: Yup.string().nullable()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      receivement,
      title: bill?.title,
      due_date: formattedBirthdate(bill?.due_date),
      provider: bill?.provider,
      type,
      transfer_value: bill?.transfer_value,
      cash_value: bill?.cash_value,
      payment: formattedBirthdate(bill?.payment),
      obs: bill?.obs
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      createAPI(values);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Editar Despesa/Receita">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Editar Despesa/Receita
          </Typography>
        </Stack>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <InputLabel htmlFor="formatted-text-mask-input">Esta conta é uma?</InputLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  {...getFieldProps('receivement')}
                  error={Boolean(touched.receivement && errors.receivement)}
                  helperText={touched.receivement && errors.receivement}
                >
                  <FormControlLabel value="Receita" control={<Radio />} label="Receita" />
                  <FormControlLabel value="Despesa" control={<Radio />} label="Despesa" />
                </RadioGroup>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Titulo"
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
                <InputLabel htmlFor="formatted-text-mask-input">Data de Vencimento</InputLabel>
                <Input
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Data de Vencimento"
                  {...getFieldProps('due_date')}
                  error={Boolean(touched.due_date && errors.due_date)}
                  helperText={touched.due_date && errors.due_date}
                  inputComponent={DateMaskInput}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Fornecedor/Cliente"
                  {...getFieldProps('provider')}
                  error={Boolean(touched.provider && errors.provider)}
                  helperText={touched.provider && errors.provider}
                />

                <InputLabel id="simple-select-label">Forma de Pagamento:</InputLabel>
                <Select
                  InputLabelProps={{ shrink: true }}
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Forma de Pagamento/Recebimento:"
                  {...getFieldProps('type')}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                >
                  <MenuItem value="Boleto">Boleto</MenuItem>
                  <MenuItem value="Dinheiro">Dinheiro</MenuItem>
                  <MenuItem value="Transferência Bancária">Transferência Bancária</MenuItem>
                  <MenuItem value="Pix">Pix</MenuItem>
                  <MenuItem value="Cartão de Crédito">Cartão de Crédito</MenuItem>
                  <MenuItem value="Consulta">Consulta</MenuItem>
                  <MenuItem value="Outro">Outro</MenuItem>
                </Select>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Valor em Dinheiro (R$)"
                  inputProps={{ inputMode: 'numeric' }}
                  {...getFieldProps('cash_value')}
                  error={Boolean(touched.cash_value && errors.cash_value)}
                  helperText={touched.cash_value && errors.cash_value}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Valor Transferido (R$)"
                  inputProps={{ inputMode: 'numeric' }}
                  {...getFieldProps('transfer_value')}
                  error={Boolean(touched.transfer_value && errors.transfer_value)}
                  helperText={touched.transfer_value && errors.transfer_value}
                />

                <InputLabel htmlFor="formatted-text-mask-input">
                  Data de Pagamento/Recebimento
                </InputLabel>
                <Input
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Data de Pagamento/Recebimento"
                  {...getFieldProps('payment')}
                  error={Boolean(touched.payment && errors.payment)}
                  helperText={touched.due_date && errors.payment}
                  inputComponent={DateMaskInput}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  label="Observações"
                  multiline
                  {...getFieldProps('obs')}
                  error={Boolean(touched.obs && errors.obs)}
                  helperText={touched.obs && errors.obs}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Editar
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
