// material
import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// layouts
import { Container, Typography, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import AuthLayout from '../layouts/AuthLayout';
import api from '../services/api';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = searchParams.get('token');

  const resetPasswordAPI = async () => {
    setIsSubmitting(true);
    const payload = { password, token };
    try {
      await api.post('/password/reset', payload).then((e) => console.log(e));
      alert('Senha alterada com sucesso!');
      navigate('/', { replace: true });
    } catch (e) {
      setIsSubmitting(false);
      alert('Ocorreu algum erro ao redefinir sua nova senha.');
    }
  };

  const handleSubmit = () => resetPasswordAPI();

  return (
    <RootStyle title="Login">
      <AuthLayout />
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Crie sua nova senha
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            InputLabelProps={{ shrink: true }}
            type="password"
            label="Insira sua nova Senha"
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            sx={{ my: 2 }}
          >
            Criar nova Senha
          </LoadingButton>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
