/* eslint-disable */
import { createContext, useState, useContext } from 'react';
import api from '../services/api';
import '../services/interceptor';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  async function Login(params) {
    try {
      const response = await api.post('/session', params, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUser(response.data.user);
      localStorage.setItem('tokenID', JSON.stringify(response.data));
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      return response.data.user;
    } catch {
      return Error;
    }
  }

  return (
    <AuthContext.Provider value={{ signed: Boolean(user), user, Login }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
