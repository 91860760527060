import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import archiveFill from '@iconify/icons-eva/archive-outline';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import api from '../../../services/api';

// ----------------------------------------------------------------------

export default function ClientMoreMenu(client) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = JSON.parse(localStorage.getItem('tokenID'));

  const deleteAPI = async () => {
    if (window.confirm('Deseja excluir este paciente?')) {
      try {
        await api.delete(`/clients/${client.id}`, {
          headers: {
            Accept: 'application/json'
          }
        });

        setIsOpen(false);
        client.setUpdate(client.id);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {user?.isAdmin && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => deleteAPI()}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        <MenuItem
          component={RouterLink}
          to={`/dashboard/pacientes/editar/${client.id}`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/dashboard/pacientes/agendamentos/${client.id}`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={eyeFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Ver Agendamentos" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {user?.isProfessional && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/prontuario/${client.id}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Icon icon={archiveFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ver Prontuários" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
