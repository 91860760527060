export const filterClients = (clients, value) => {
  const newarr = clients.filter((client) => {
    if (!value) return true;
    if (client.name.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }
    return false;
  });
  return newarr;
};
