import * as Yup from 'yup';
import { useState, forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MuiAlert from '@mui/material/Alert';

import { useAuth } from '../../../contexts/auth';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { Login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [snack, setSnack] = useState(false);
  const user = JSON.parse(localStorage.getItem('tokenID'));

  useEffect(() => {
    if (user) {
      navigate('/dashboard/app', { replace: true });
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O e-mail precisa ser válido').required('Email é obrigatório'),
    password: Yup.string().required('Senha é obrigatório')
  });

  const loginAPI = async (values) => {
    const response = await Login(values);
    if (response === Error) {
      setSnack(true);
      return false;
    }
    navigate('/dashboard/app', { replace: true });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      await loginAPI(values);
      formik.setSubmitting(false);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ my: 2 }}
        >
          Login
        </LoadingButton>
      </Form>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Credenciais inválidas!
        </Alert>
      </Snackbar>
    </FormikProvider>
  );
}
