import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import { useState, useEffect, forwardRef } from 'react';

// material
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { formattedBirthdate } from './_user/utils';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { MedicalMoreMenu } from '../components/_dashboard/user';

import api from '../services/api';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// ----------------------------------------------------------------------

export default function MedicalRecord() {
  const navigate = useNavigate();
  const { user } = JSON.parse(localStorage.getItem('tokenID'));
  const { clientId } = useParams();
  const [medicalRecords, setMedicalRecord] = useState([]);
  const [update, setUpdate] = useState(0);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };

  const apiMedicalRecord = async () => {
    try {
      setLoading(true);
      const request = await api.get(`/medical-records/${clientId}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setMedicalRecord(request.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (update !== 0) {
      setSnack(true);
    }
    apiMedicalRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  if (user?.isProfessional === false) {
    navigate(`/dashboard`, { replace: true });
  }

  return (
    <Page title="Prontuário Eletrônico | Psicicologia Medica">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listar Todas Prontuário Eletrônicos
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`/dashboard/prontuario/criar/${clientId}`}
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar Prontuário
          </Button>
        </Stack>

        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Data do Prontuário</TableCell>
                    <TableCell align="right">Paciente</TableCell>
                    <TableCell align="right">Profissional</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicalRecords.map((medicalRecord) => (
                    <TableRow key={medicalRecord?.id} sx={{ py: 3 }}>
                      <TableCell component="th" scope="row">
                        {formattedBirthdate(medicalRecord?.date)}
                      </TableCell>
                      <TableCell align="right">{medicalRecord?.client_id?.name}</TableCell>
                      <TableCell align="right">{medicalRecord?.user_id?.name}</TableCell>
                      <TableCell align="right">
                        <MedicalMoreMenu medicalRecord={medicalRecord} setUpdate={setUpdate} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </Card>
      </Container>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Sala Deletada com Sucesso
        </Alert>
      </Snackbar>
    </Page>
  );
}
