import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import RecoveryPassword from './pages/RecoveryPassword';
import ResetPassword from './pages/ResetPassword';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';

import User from './pages/User';
import CreateUser from './pages/_user/Create';
import EditUser from './pages/_user/Edit';

import Client from './pages/Client';
import ClientProfessional from './pages/ClientProfessional';
import CreateClient from './pages/_client/Create';
import EditClient from './pages/_client/Edit';
import SchedulesClient from './pages/_client/Schedule';
import PrintClient from './pages/_client/Print';

import Bill from './pages/Bill';
import CreateBill from './pages/_bill/Create';
import EditBill from './pages/_bill/Edit';

import Schedule from './pages/Schedule';
import Calendar from './pages/_schedule/Calendar';
import CreateSchedule from './pages/_schedule/Create';
import EditSchedule from './pages/_schedule/Edit';
import FilterSchedule from './pages/FilterSchedule';

import MedicalRecord from './pages/MedicalRecord';
import CreateMedicalReport from './pages/_medical/Create';
import EditMedicalReport from './pages/_medical/Edit';

import Room from './pages/Room';
import EditRoom from './pages/_room/Edit';
import CreateRoom from './pages/_room/Create';
import CalendarRoom from './pages/_room/Calendar';

// import Invoice from './pages/Invoice';
import FilterInvoices from './pages/FilterInvoices';
import CreateInvoice from './pages/_invoice/Create';

import NotFound from './pages/Page404';

import { useAuth } from './contexts/auth';

// ----------------------------------------------------------------------

function RouterAuth() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },

        { path: 'funcionarios', element: <User /> },
        { path: 'funcionarios/criar', element: <CreateUser /> },
        { path: 'funcionarios/editar/:id', element: <EditUser /> },

        { path: 'pacientes', element: <Client /> },
        { path: 'pacientes/profissional', element: <ClientProfessional /> },
        { path: 'pacientes/criar', element: <CreateClient /> },
        { path: 'pacientes/editar/:id', element: <EditClient /> },
        { path: 'pacientes/agendamentos/:id', element: <SchedulesClient /> },

        { path: 'contas', element: <Bill /> },
        { path: 'contas/criar', element: <CreateBill /> },
        { path: 'contas/editar/:id', element: <EditBill /> },

        { path: 'agendamento', element: <Schedule /> },
        { path: 'agendamento/:user', element: <Calendar /> },
        { path: 'agendamento/:user/criar', element: <CreateSchedule /> },
        { path: 'agendamento/:user/editar/:scheduleId', element: <EditSchedule /> },
        { path: 'agendamentos/filtro', element: <FilterSchedule /> },

        { path: 'prontuario/:clientId', element: <MedicalRecord /> },
        { path: 'prontuario/criar/:clientId', element: <CreateMedicalReport /> },
        { path: 'prontuario/ver/:id', element: <EditMedicalReport /> },

        { path: 'salas', element: <Room /> },
        { path: 'salas/criar', element: <CreateRoom /> },
        { path: 'salas/editar/:id', element: <EditRoom /> },
        { path: 'salas/agendamento/:roomId', element: <CalendarRoom /> },

        { path: 'notas-fiscais', element: <FilterInvoices /> },
        { path: 'notas-fiscais/criar', element: <CreateInvoice /> },

        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'register', element: <Register /> },
        { path: 'recuperar-senha', element: <RecoveryPassword /> },
        { path: 'alterar-senha', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Login /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: 'dashboard/pacientes/imprimir/:id', element: <PrintClient /> }
  ]);
}

function RouterNoAuth() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: 'recuperar-senha', element: <RecoveryPassword /> },
        { path: 'alterar-senha', element: <ResetPassword /> },
        { path: '/', element: <Login /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default function Router() {
  const { signed } = useAuth();
  const user = JSON.parse(localStorage.getItem('tokenID'));
  return signed || user ? <RouterAuth /> : <RouterNoAuth />;
}
