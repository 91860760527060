import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography, Table, TableRow, TableBody, TableCell } from '@mui/material';

import { formattedBirthdate } from '../_user/utils';
import api from '../../services/api';

export default function PrintClient() {
  const { id } = useParams();
  const [client, setClients] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const isUser = (name) => {
    const hasName = users.find((user) => user.id === name);
    return hasName ? hasName.name : name;
  };

  const apiGetClients = async () => {
    try {
      const request = await api.get(`/clients/${id}`, {
        headers: {
          Accept: 'application/json'
        }
      });
      document.title = request.data.name;
      setClients(request.data);
    } catch (e) {
      console.error(e);
    }
  };

  const apiGetUsers = async () => {
    try {
      const request = await api.get(`/users`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUsers(request.data);
      setTimeout(() => window.print(), 1000);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    apiGetClients();
    apiGetUsers();
  }, []);

  return (
    <div>
      <Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Nome: {client?.name}
        </Typography>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Primeiro Atendimento:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.first_visit}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Telefone:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.phone}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Data de Nascimento:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {formattedBirthdate(client?.birthdate)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>CPF:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.cpf}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Ocupação:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.occupation}
              </TableCell>
            </TableRow>
          </TableBody>

          <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: '10px' }}>ENDEREÇO</p>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>CEP:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.cep}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Rua:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.street}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Numero:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.number}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Complemento:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.complement}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Bairro:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.district}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Cidade:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.city}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Estado:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.state}
              </TableCell>
            </TableRow>
          </TableBody>
          <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: '10px' }}>ACOMPANHAMENTO</p>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Acompanhado(a) pelo Profissional:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {isUser(client?.user_id)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>
                Acompanhado(a) tambem pelo Profissional:
              </TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {isUser(client?.user2_id)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>
                Acompanhado(a) tambem pelo Profissional:
              </TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {isUser(client?.user3_id)}
              </TableCell>
            </TableRow>
          </TableBody>

          <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: '10px' }}>CONTATOS</p>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Contato da Mãe:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_mother_name}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Telefone da Mãe:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_mother_number}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Contato do Pai:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_father_name}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Telefone do Pai:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_father_number}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Outro Contato:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_other_name}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Telefone do Outro Contato:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_other_number}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Contato de Emergência:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_emergency}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Contato de Emergência 2:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.contact_emergency2}
              </TableCell>
            </TableRow>
          </TableBody>

          <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: '10px' }}>DADOS FISCAIS</p>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Nome Responsável Fiscal:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_name}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Documento do Responsável Fiscal:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_document}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>CEP:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_cep}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Rua:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_street}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Numero:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_number}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Complemento:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_complement}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Bairro:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_district}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Cidade:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_city}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '10px' }}>Estado:</TableCell>
              <TableCell sx={{ fontSize: '10px' }} scope="row">
                {client?.fiscal_state}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}
