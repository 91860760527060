import { useEffect, useState, forwardRef } from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Stack,
  Container,
  Typography,
  Card,
  TextField,
  Input,
  Grid,
  InputLabel
} from '@mui/material';
import { IMaskInput } from 'react-imask';

import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { formattedBirthdate } from '../_user/utils';

import api from '../../services/api';

const BirthdateMaskInput = forwardRef((props, ref) => {
  const { ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      overwrite
    />
  );
});

export default function EditMedicalReport() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [medicalReport, setMedicalReport] = useState();
  const [client, setClient] = useState();
  const [user, setUser] = useState();

  const getAPI = async () => {
    try {
      const medicalData = await api.get(`/medical-records/single/${id}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setMedicalReport(medicalData.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getClient = async () => {
    try {
      const clientData = await api.get(`/clients/${medicalReport.client_id}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setClient(clientData.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getUser = async () => {
    try {
      const userData = await api.get(`/users/${medicalReport.user_id}`, {
        headers: {
          Accept: 'application/json'
        }
      });

      setUser(userData.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!medicalReport) {
      getAPI();
    } else {
      getUser();
      getClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalReport]);

  const editAPI = async (values) => {
    try {
      await api.put(`/medical-records/${id}`, values, {
        headers: {
          Accept: 'application/json'
        }
      });
      console.log(medicalReport);
      navigate(`/dashboard/prontuario/${medicalReport?.client_id}`, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const CreateSchema = Yup.object().shape({
    description: Yup.string().nullable(),
    familiar_description: Yup.string().nullable(),
    profissional_description: Yup.string().nullable(),
    diagnostic_hypothesis: Yup.string().nullable(),
    therapeutic_conduct: Yup.string().nullable(),
    case_evolution: Yup.string().nullable(),
    forwarding_record: Yup.string().nullable(),
    closing: Yup.string().nullable()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: formattedBirthdate(medicalReport?.date),
      date_end: formattedBirthdate(medicalReport?.date_end),
      description: medicalReport?.description,
      familiar_description: medicalReport?.familiar_description,
      profissional_description: medicalReport?.profissional_description,
      diagnostic_hypothesis: medicalReport?.diagnostic_hypothesis,
      therapeutic_conduct: medicalReport?.therapeutic_conduct,
      case_evolution: medicalReport?.case_evolution,
      forwarding_record: medicalReport?.forwarding_record,
      closing: medicalReport?.closing
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      values.date = formattedBirthdate(values.date);
      values.date_end = formattedBirthdate(values.date_end);
      values.client_id = medicalReport.client_id;
      values.user_id = medicalReport.user_id;
      editAPI(values);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Editar Prontuário Eletrônico">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Editar Prontuário Eletrônico:
          </Typography>
        </Stack>

        <Card sx={{ p: 5, mb: 2 }}>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h4" gutterBottom>
                Identificação do Profissional:
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h6" gutterBottom>
                Nome: {user?.name}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                Formação: {user?.occupation}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                CRP: {user?.crp}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ p: 5, mb: 2 }}>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h4" gutterBottom>
                Identificação Paciente:
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                Nome: {client?.name}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6" gutterBottom>
                Data de Nascimento: {formattedBirthdate(client?.birthdate)}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ p: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Informações:
                </Typography>

                <div>
                  <InputLabel htmlFor="Data">Data de Início do Processo</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    label="Data"
                    {...getFieldProps('date')}
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    inputComponent={BirthdateMaskInput}
                  />
                </div>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  type="text"
                  value={medicalReport?.description}
                  label="Descrição da Demanda"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  value={medicalReport?.familiar_description}
                  label="Histório Familiar"
                  {...getFieldProps('familiar_description')}
                  error={Boolean(touched.familiar_description && errors.familiar_description)}
                  helperText={touched.familiar_description && errors.familiar_description}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  value={medicalReport?.profissional_description}
                  label="Histório Profissional"
                  {...getFieldProps('profissional_description')}
                  error={Boolean(
                    touched.profissional_description && errors.profissional_description
                  )}
                  helperText={touched.profissional_description && errors.profissional_description}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  value={medicalReport?.diagnostic_hypothesis}
                  label="Hipótese Diagnóstica"
                  {...getFieldProps('diagnostic_hypothesis')}
                  error={Boolean(touched.diagnostic_hypothesis && errors.diagnostic_hypothesis)}
                  helperText={touched.diagnostic_hypothesis && errors.diagnostic_hypothesis}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  value={medicalReport?.therapeutic_conduct}
                  label="Conduta terapêutica"
                  {...getFieldProps('therapeutic_conduct')}
                  error={Boolean(touched.therapeutic_conduct && errors.therapeutic_conduct)}
                  helperText={touched.therapeutic_conduct && errors.therapeutic_conduct}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={10}
                  value={medicalReport?.case_evolution}
                  label="Evolução de Caso"
                  {...getFieldProps('case_evolution')}
                  error={Boolean(touched.case_evolution && errors.case_evolution)}
                  helperText={touched.case_evolution && errors.case_evolution}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  value={medicalReport?.forwarding_record}
                  label="Registro de Encaminhamento"
                  {...getFieldProps('forwarding_record')}
                  error={Boolean(touched.forwarding_record && errors.forwarding_record)}
                  helperText={touched.forwarding_record && errors.forwarding_record}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  value={medicalReport?.closing}
                  label="Encerramento"
                  {...getFieldProps('closing')}
                  error={Boolean(touched.closing && errors.closing)}
                  helperText={touched.closing && errors.closing}
                />

                <div>
                  <InputLabel htmlFor="Data">Data de Encerramento</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    label="Data"
                    {...getFieldProps('date_end')}
                    error={Boolean(touched.date_end && errors.date_end)}
                    helperText={touched.date_end && errors.date_end}
                    inputComponent={BirthdateMaskInput}
                  />
                </div>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Editar Prontuário
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}
